<template>
  <div class="notify-content">
    <div class="container-fluid mt--5">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">
                Notifications
                <el-button
                  v-if="reloadNotify"
                  size="mini"
                  type="danger"
                  icon="el-icon-refresh"
                  plain
                  round
                  @click="reloadNotification"
                  >Load new notification</el-button
                >
              </h3>
            </div>
            <div class="card-body">
              <el-table
                :data="tableData"
                border
                style="width: 100%"
                size="mini"
                :row-class-name="tableRowClassName"
              >
                <el-table-column
                  prop="type"
                  label="Type"
                  align="center"
                  width="160"
                ></el-table-column>
                <el-table-column
                  prop="message"
                  header-align="center"
                  min-width="200"
                  class-name="line-break"
                  label="Message"
                ></el-table-column>
                <el-table-column
                  prop="datetimeFormat"
                  label="Date Time"
                  align="center"
                  width="180"
                ></el-table-column>
              </el-table>
              <el-pagination
                class="pagination-notify"
                @size-change="setPageSize"
                @current-change="setPage"
                :current-page.sync="page"
                :page-sizes="[10, 20, 50, 100, 200]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalItem"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationApi from "@/api/notification";
export default {
  name: "notificationPage",
  computed: {
    userType() {
      return this.$store.getters.usertype;
    },
  },
  data() {
    return {
      loading: false,
      tableData: [],
      pageSize: 20,
      page: 1,
      totalItem: 0,
      reloadNotify: false,
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 'unread') {
        return "unread-notification";
      }
      return "";
    },
    getNitifyList() {
      this.loading = true;
      let query = {
        page: this.page,
        items_per_page: this.pageSize,
        order: "desc",
      };
      NotificationApi.getList(query).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.tableData = data.records;
          this.totalItem = data.totalItem;
          this.reloadNotify = false;
          this.markRead();
        }
      });
    },
    setPage(val) {
      this.page = val;
      this.getNitifyList();
    },
    setPageSize(val) {
      this.pageSize = val;
      this.getNitifyList();
    },

    reloadNotification() {
      this.getNitifyList();
      this.markRead();
    },

    markRead() {
      NotificationApi.sendMarkread().then(({ result }) => {
        if (result) {
          if (this.userType == "walletuser") {
            this.$emit("updateNotify");
          } else {
            this.$emit("updateBalance");
          }
        }
      });
    },

  },
  mounted() {
    this.getNitifyList();
    this.$root.$on('reload-notification', ()=>{
      this.reloadNotify = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.notify-content {
  .pagination-notify {
    margin-top: 10px;
  }
}
</style>
